/**
 * search for match in any string
 */
// let found = false;
// export default function(items, searchTerm, excluded) {
//   const _searchTerm = searchTerm.toLowerCase();
//   const rtn = items.filter((item) => {
//     let result = false;
//     iterate(item, _searchTerm, excluded);
//     if (found) {
//       result = true;
//       found = false;
//     }
//     return result;
//   });
//   return rtn;
// }

// function iterate(obj, searchTerm, excluded) {
//   for (const key in obj) {
//     if (typeof obj[key] === "object") {
//       iterate(obj[key], searchTerm, excluded);
//     } else if (typeof obj[key] === "string" && !excluded.includes(key)) {
//       if (obj[key].toLowerCase().includes(searchTerm.toLowerCase())) {
//         found = true;
//         break;
//       }
//     }
//   }
// }

/*** 
  search for match starting with searchterm
*/
let found = false;
export default function(items, searchTerm, excluded) {
  const _searchTerm = searchTerm.toLowerCase().trim();
  const rtn = items.filter((item) => {
    let result = false;
    iterate(item, _searchTerm, excluded);
    if (found) {
      result = true;
      found = false;
    }
    return result;
  });
  return rtn;
}

function iterate(obj, searchTerm, excluded = []) {
  for (const key in obj) {
    if (typeof obj[key] === "object") {
      iterate(obj[key], searchTerm, excluded);
    } else if (typeof obj[key] === "string" && !excluded.includes(key)) {
      const arr = replace(obj[key], searchTerm).split(" ");
      for (const el of arr) {
        if (el.toLowerCase().startsWith(searchTerm)) {
          found = true;
          break;
        }
      }
    }
  }
}

function replace(string, searchTerm) {
  let newString = string
    .replace(",", "")
    .replace(".", "")
    .replace("'", "");
  if (!searchTerm.includes("-")) {
    newString.replace("-", "");
  }
  return newString;
}
