<template>
  <base-bg>
    <div>
      <top-nav-bar></top-nav-bar>
      <section class="page with-footer">
        <div class="container">
          <base-list-title :title="title"></base-list-title>
          <base-search
            @updatedSearchTerm="updateSearchTerm"
            :searchTerm="searchTerm"
          ></base-search>
          <div class="my-3 row justify-content-around">
            <base-tag
              class="p-1"
              v-for="tag in ingredientTags"
              :key="tag.id"
              :title="tag.title"
              :active="tag.isActive"
              :width="tagWidth"
              @click.native="ingredientTagClicked(tag.id)"
            ></base-tag>
          </div>
          <base-list
            :items="elements"
            :page="$routeNames.routeIngredientPage"
            :showLikes="false"
          ></base-list>
        </div>
      </section>
    </div>
  </base-bg>
</template>

<script>
import filteredItems from "../../assets/helpers/filter.js";
import converter from "../../assets/helpers/converter.js";
import { mapGetters, mapActions } from "vuex";
export default {
  inject: ["activePage"],
  data() {
    return {
      ingredientTags: [
        { id: 0, title: "Jan", isActive: false },
        { id: 1, title: "Feb", isActive: false },
        { id: 2, title: "Mär", isActive: false },
        { id: 3, title: "Apr", isActive: false },
        { id: 4, title: "Mai", isActive: false },
        { id: 5, title: "Jun", isActive: false },
        { id: 6, title: "Jul", isActive: false },
        { id: 7, title: "Aug", isActive: false },
        { id: 8, title: "Sep", isActive: false },
        { id: 9, title: "Okt", isActive: false },
        { id: 10, title: "Nov", isActive: false },
        { id: 11, title: "Dez", isActive: false },
      ],
      searchTerm: "",
      month: 0,
    };
  },
  computed: {
    ...mapGetters(["getIngredients", "getIngredientSearch"]),
    elements() {
      let items = this.getIngredients.slice();

      if (this.searchTerm != "") {
        const itemsTitleOnly = items.map((x) => {
          let y = {};
          y.id = x.id;
          y.title = x.title;
          y.path = x.path;
          y.season = x.season;
          return y;
        });
        const fItems = filteredItems(itemsTitleOnly, this.searchTerm, [
          "path",
          "cleanLink",
          "link",
        ]);
        return fItems;
      }

      //console.log(items);
      if (!this.areAnyFiltersActive) {
        const month = converter.getMonth();
        const seasonalItems = items.filter((item) => {
          return item.months.includes(month);
        });
        return seasonalItems;
      }

      items = this.filterIngredientTags(items);

      return items;
    },
    title() {
      let string = "";
      if (!this.areAnyFiltersActive) {
        const month = converter.getMonthNameOnMonth();
        string = `Das sind unsere <strong>${month}</strong>-Zutaten - über die Suche findet Ihr
       auch die Zutaten für andere Monate`;
      } else if (this.searchTerm != "") {
        string = `Die Zutaten mit Deinem Suchbegriff - unabhängig des aktuellen Monats`;
      } else {
        string = `Die Suchergebnisse entsprechend Deiner Filter`;
      }

      return string;
    },
    tagWidth() {
      const width = this.$screen.width;
      if (width < 768) {
        return 80;
      } else if (width < 1024) {
        return 120;
      } else {
        return 100;
      }
    },
    areAnyFiltersActive() {
      let anyTagIsActive = false;
      for (const tag of this.ingredientTags) {
        if (tag.isActive) {
          if (tag.id != converter.getMonth() - 1) {
            anyTagIsActive = true;
          }
        }
      }

      if (this.searchTerm == "" && !anyTagIsActive) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    ...mapActions(["saveIngredientSearch"]),
    ingredientTagClicked(index) {
      this.ingredientTags[index].isActive =
        !this.ingredientTags[index].isActive;
    },
    saveSearch() {
      let obj = {
        ingredientTags: this.ingredientTags,
        searchTerm: this.searchTerm,
      };
      this.saveIngredientSearch(obj);
    },
    filterIngredientTags(items) {
      const areAnyFiltersActive = this.ingredientTags.filter((item) => {
        return item.isActive;
      });
      if (areAnyFiltersActive.length == 0) {
        return items;
      }

      return items.filter((item) => {
        const months = item.months;
        let found = false;
        for (const month of months) {
          if (isNaN(month)) {
            break;
          }
          if (this.ingredientTags[month - 1].isActive) {
            found = true;
          }
        }
        return found;
      });
    },
    updateSearchTerm(val) {
      this.searchTerm = val;
    },
  },
  watch: {
    searchTerm(nv, ov) {
      if (nv.length >= 1 && ov.length == 0) {
        this.ingredientTags = this.ingredientTags.map((x) => {
          x.isActive = false;
          return x;
        });
      } else if (nv.length == 0 && ov.length >= 1) {
        this.ingredientTags[this.month].isActive = true;
      }
    },
  },
  created() {
    this.month = new Date().getMonth();
    if (this.getIngredientSearch) {
      this.ingredientTags = this.getIngredientSearch.ingredientTags;
      this.searchTerm = this.getIngredientSearch.searchTerm;
    } else {
      this.ingredientTags[this.month].isActive = true;
    }

    this.activePage.value = this.$routeNames.routeIngredientsList;
  },
  beforeRouteLeave(_, __, next) {
    this.saveSearch();
    next();
  },
};
</script>

<style scoped></style>
